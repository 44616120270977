import React from 'react';
import './Services.css'; // Make sure to adjust the path if needed
import f1 from '../assets/f1.jpg'; // Assuming f1.jpg is located in the correct path
import f2 from '../assets/f2.jpg'; // Assuming f2.jpg is located in the correct path
import f3 from '../assets/f3.jpg'; // Assuming f3.jpg is located in the correct path
import f4 from '../assets/react.png'; // Assuming react.png is located in the correct path
import f5 from '../assets/mongo.jpg'; // Assuming mongo.jpg is located in the correct path
import f6 from '../assets/java.png'; // Assuming java.png is located in the correct path
import f7 from '../assets/html.png'; // Assuming html.png is located in the correct path
import f8 from '../assets/mysql.jpg'; // Assuming mysql.jpg is located in the correct path
import webDevLogo from '../assets/webd.jpg'; // Assuming webDevLogo.jpg is located in the correct path
import cyber from '../assets/cyber.jpg'; // Assuming webDevLogo.jpg is located in the correct path
import data from '../assets/datas.jpg'; // Assuming webDevLogo.jpg is located in the correct path
import cla from '../assets/cloud.jpg'; // Assuming webDevLogo.jpg is located in the correct path
import ap from '../assets/api.png'; // Assuming webDevLogo.jpg is located in the correct path
import testing from '../assets/testing.png'; // Assuming webDevLogo.jpg is located in the correct path

function Services() {
  return (
    <div>
      {/* Service Boxes Section */}
      <section id="service-boxes">
        <h2 className="section-heading">Our Services</h2>
        <div className="service-boxes-container">
          {/* Service Box 1 */}
          <div className="service-box">
            <img src={webDevLogo} alt="Web Development Logo" className="service-logo" />
            <h3>Web Development</h3>
            <p>Our web application development services include designing and building responsive, high-performance web applications. We leverage the latest technologies to ensure your web apps are fast, secure, and scalable:</p>
          </div>
          {/* Service Box 2 */}
          <div className="service-box">
            <img src={cyber} alt="Cybersecurity Logo" className="service-logo" />
            <h3>Cybersecurity</h3>
            <p>Protect your business with our comprehensive cybersecurity solutions. We offer services such as vulnerability assessments, threat detection, and incident response to safeguard your digital assets.</p>
          </div>
          {/* Service Box 3 */}
          <div className="service-box">
            <img src={data} alt="Database Management Logo" className="service-logo" />
            <h3>Database Management</h3>
            <p>Our database management services ensure that your data is organized, secure, and easily accessible. We specialize in designing, optimizing, and maintaining databases to support your business operations.</p>
          </div>
          {/* Service Box 4 */}
          <div className="service-box">
            <img src={cla} alt="Cloud Services Logo" className="service-logo" />
            <h3>Cloud Services</h3>
            <p>Our cloud services include cloud migration, management, and optimization. We help you leverage the benefits of the cloud for increased flexibility, scalability, and cost-efficiency:</p>
          </div>
          {/* Service Box 5 */}
          <div className="service-box">
            <img src={ap} alt="API Logo" className="service-logo" />
            <h3>API</h3>
            <p>At Codevocado, we specialize in providing top-notch API services that empower your business to seamlessly integrate and interact with various software applications. Our API services are designed to ensure efficient communication and data exchange between different systems, enhancing your business processes and driving innovation.</p>
          </div>
          {/* Service Box 6 */}
          <div className="service-box">
            <img src={testing} alt="API Testing Logo" className="service-logo" />
            <h3>Testing of (API web site, web application)</h3>
            <p>Our API testing and validation services ensure your APIs are functioning correctly and efficiently. We conduct rigorous testing to identify and fix any issues, ensuring your APIs deliver reliable and consistent performance.</p>
          </div>
        </div>
      </section>

      {/* Language Icons Section */}
      <section className="language-icons-container">
        <div className="language-icons">
          <img src={f1} alt="PHP" />
          <img src={f8} alt="MySQL" />
          <img src={f2} alt="JavaScript" />
          <img src={f3} alt="Python" />
          <img src={f4} alt="ReactJS" />
          <img src={f5} alt="MongoDB" />
          <img src={f6} alt="Java" />
          <img src={f7} alt="HTML" />
          {/* Add more language icons as needed */}
        </div>
      </section>
    </div>
  );
}

export default Services;
