import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';
import footerImage from '../assets/foter.jpg';
import footer2 from '../assets/l213.jpg';

const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const FooterWrapper = styled.footer`
  background-color: #333;
  color: #fff;
  padding: 40px 20px;
  animation: ${slideUp} 0.6s ease-out;
`;

const FooterTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const FooterSection = styled.div`
  flex: 1;
  margin-bottom: 20px;
  animation: ${fadeIn} 0.6s ease-out;
  min-width: 200px; /* Ensures sections don't become too narrow */
`;

const FooterLinksList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const FooterLink = styled.a`
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease-out;

  &:hover {
    text-decoration: underline;
    color: #007bff;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  flex-direction: column; /* Align icons vertically */
  align-items: center; /* Center align icons */
`;

const SocialIcon = styled.a`
  color: #fff;
  font-size: 20px;
  margin-bottom: 10px; /* Add space between icons */
  text-decoration: none;
  transition: color 0.3s ease-out;

  &:hover {
    color: #007bff;
  }
`;

const FooterBottom = styled.div`
  text-align: center;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #666;
  animation: ${slideUp} 0.6s ease-out;
`;

const CareerSection = styled.div`
  flex: 1;
  margin-bottom: 20px;
  animation: ${fadeIn} 0.6s ease-out;
`;

const FooterImage = styled.img`
  width: 80px; /* Increased width */
  height: 80px; /* Increased height */
  border-radius: 50%; /* Make the image circular */
  object-fit: cover; /* Ensure the image covers the circle */
  margin: 10px; /* Add space around the image */
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterTop>
        <FooterSection>
          <h3>Company Info</h3>
          <p><FooterLink href="aboutus">About Us</FooterLink></p>
          <p><FooterLink href="inquiry">Contact Us</FooterLink></p>
        </FooterSection>
        <FooterSection>
          <h3>Services</h3>
          <FooterLinksList>
            <li><FooterLink href="#">Web Development</FooterLink></li>
            <li><FooterLink href="#">Cybersecurity</FooterLink></li>
            <li><FooterLink href="#">Database Management</FooterLink></li>
            <li><FooterLink href="#">Custom Software</FooterLink></li>
            <li><FooterLink href="#">Mobile App Development</FooterLink></li>
            <li><FooterLink href="#">AI Solutions</FooterLink></li>
            <li><FooterLink href="#">Cloud Services</FooterLink></li>
          </FooterLinksList>
        </FooterSection>
        <CareerSection>
          <h3>Career</h3>
          <FooterLink href="career">Software Full Stack Developer Intern</FooterLink>
            <FooterLink href="career">Marketing and Sales Intern</FooterLink>
            <FooterLink href="career">HR Intern</FooterLink>
            <FooterLink href="career">Business Development Intern</FooterLink>
        </CareerSection>
        <FooterSection>
          <h3>Address</h3>
          <p>Upadhyayinfotech,</p>
          <p>18 Deubari Deoria, UP, 274604</p>
          <p>Phone: +91-0556167890</p>
          <p>Email: <FooterLink href="mailto:info@codevocado.in">info@codevocado.in</FooterLink></p>
        </FooterSection>
        <FooterSection>
          <h3>Follow Us</h3>
          <SocialIcons>
            <SocialIcon href="https://www.facebook.com/codevocado" target="_blank" rel="noopener noreferrer"><FaFacebook /></SocialIcon>
            <SocialIcon href="https://twitter.com/codevocado" target="_blank" rel="noopener noreferrer"><FaTwitter /></SocialIcon>
            <SocialIcon href="https://www.linkedin.com/company/codevocado" target="_blank" rel="noopener noreferrer"><FaLinkedin /></SocialIcon>
          </SocialIcons>
        </FooterSection>
      </FooterTop>
      <FooterBottom>
        <p>&copy; 2024 codevocado. All rights reserved.</p>
        <FooterImage src={footerImage} alt="Footer Image 1" />
        <FooterImage src={footer2} alt="Footer Image 2" />
      </FooterBottom>
    </FooterWrapper>
  );
}

export default Footer;
