import React from 'react';

const GoogleMap = () => {
  return (
    <div style={{ width: '100%', height: '500px' }}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.836054853616!2d144.9632803158926!3d-37.81621867975156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf577c1b0c75997c8!2sFederation%20Square!5e0!3m2!1sen!2sau!4v1645599674123!5m2!1sen!2sau"
        width="100%"
        height="100%"
        style={{ border: '0' }}
        allowFullScreen=""
        loading="lazy"
      ></iframe>
    </div>
  );
};

export default GoogleMap;
