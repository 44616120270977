import React from 'react';
import styled from 'styled-components';
import client1 from '../assets/army.jpg'; // Example client logo
import client2 from '../assets/army2.jpg'; // Example client logo

const PageWrapper = styled.div`
  font-family: Arial, sans-serif;
  background-color: #f9f9f9; /* Light background color for better contrast */
`;

const HeroSection = styled.section`
  background: url('../assets/hero-bg.jpg') no-repeat center center/cover;
  color: #fff;
  padding: 60px 20px;
  text-align: center;
  background-color: #007bff; /* Background color for better contrast */
  background-blur: 10px; /* Adds a subtle blur effect */
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #fff; /* Ensure title color contrasts with background */
`;

const Introduction = styled.p`
  font-size: 1.2rem;
  color: #e0e0e0; /* Slightly lighter color for better readability */
  margin-top: 0;
`;

const ClientLogos = styled.section`
  padding: 40px 20px;
  text-align: center;
  background-color: #fff; /* White background for logos section */
  border-top: 1px solid #ddd; /* Light border to separate sections */
`;

const LogosContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
`;

const Logo = styled.img`
  width: 150px;
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  border-radius: 10px; /* Rounded corners for logos */
`;

const Testimonials = styled.section`
  padding: 40px 20px;
  background-color: #f4f4f4; /* Light background for testimonials */
  border-top: 1px solid #ddd; /* Light border for separation */
`;

const Testimonial = styled.div`
  margin-bottom: 20px;
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const TestimonialText = styled.p`
  font-size: 1rem;
  color: #333; /* Dark text color for readability */
`;

const TestimonialAuthor = styled.p`
  font-weight: bold;
  margin-top: 10px;
  color: #555; /* Slightly lighter color for author */
`;

const CallToAction = styled.section`
  background-color: #007bff;
  color: #fff;
  text-align: center;
  padding: 40px 20px;
  border-top: 1px solid #0056b3; /* Darker border for emphasis */
`;

const CallToActionText = styled.p`
  font-size: 1.2rem;
  margin: 0;
`;

const CallToActionButton = styled.a`
  background-color: #fff;
  color: #007bff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  margin-top: 20px;
  display: inline-block;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const Clients = () => {
  return (
    <PageWrapper>
      <HeroSection>
        <Title>Our Clients</Title>
        <Introduction>We are proud to work with a diverse range of clients who trust us with their needs.</Introduction>
      </HeroSection>

      <ClientLogos>
        <h2>Trusted By</h2>
        <LogosContainer>
          <Logo src={client1} alt="Client 1" />
          <Logo src={client2} alt="Client 2" />
          {/* Add more logos as needed */}
        </LogosContainer>
      </ClientLogos>

      <Testimonials>
        <h2>What Our Clients Say</h2>
        <Testimonial>
          <TestimonialText>"Since implementing Codevocado's School Management Software at Little Flower High School, we've seen a dramatic improvement in our administrative efficiency. The intuitive design and comprehensive features have made it easier than ever for our staff to manage daily operations, from attendance to grade tracking. It's a game-changer!"</TestimonialText>
          <TestimonialAuthor>Army Public School</TestimonialAuthor>
        </Testimonial>
        {/* Add more testimonials as needed */}
      </Testimonials>

      <CallToAction>
        <CallToActionText>Want to see what we can do for you?</CallToActionText>
        <CallToActionButton href="#inquiry">Get in Touch</CallToActionButton>
      </CallToAction>
    </PageWrapper>
  );
};

export default Clients;
