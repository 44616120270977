import React from 'react';
import './App.css';
import Header from './components/Header';
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import ValueAddition from './components/ValueAddition';
import Offer from './components/Offer';
// import Cost from './components/Cost';
import Footer from './components/Footer';
import Inquiry from './components/Inquiry';
import whatsappImg from './assets/whatsapp.png'; // Import the image
import Chatbot from './components/Chatbot';
import Scroll from './components/Scroll'; // Import the ScrollButton component
import Clients from './components/Clients'; // Import the ScrollButton component
import Freetrialsection from './components/Freetrialsection'; // Import the ScrollButton component
import GoogleMap from './components/Googlemap'; // Import the ScrollButton component
import CareerPage from './components/Career';

function App() {
  return (
    <div className="App">
      <div id="header">
        <Header />
      </div>

      <div id="services">
        <Services />
      </div>
      <div id="inquiry">
        <Inquiry />
      </div>
      <div id="aboutus">
        <AboutUs />
      </div>
      <div id="career">
        <CareerPage />
      </div>
      <ValueAddition />
      <Offer />
      <Clients/>
      <Freetrialsection/>
      <GoogleMap/>

      <Footer />

      {/* WhatsApp Chat Button */}
      <a 
        href="https://wa.me/9005217129?text=Hello%20I%20need%20help" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="whatsapp-button"
      >
        <img src={whatsappImg} alt="Chat with us on WhatsApp"/>
      </a>

      {/* Chatbot Button */}
      <Chatbot />

      {/* Scroll Button */}
      <Scroll />
    </div>
  );
}

export default App;
