import React, { useState } from 'react';
import axios from 'axios';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-phone-input-2/lib/style.css';
import './Header.css'; // Import your custom CSS file

import PhoneInput from 'react-phone-input-2';
import logo from '../assets/m10.jpg'; // Import your logo image

import m2 from '../assets/m2.jpg';
import m1 from '../assets/m1.jpg';
import m3 from '../assets/m3.jpg';
import m4 from '../assets/m4.jpg';
import m5 from '../assets/m5.jpg';
import m6 from '../assets/m6.jpg';
import m7 from '../assets/m7.jpg';
import m11 from '../assets/m11.png';
import m12 from '../assets/m12.jpg';
import m13 from '../assets/m13.jpg';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const softwareData = [
  { title: 'Codevocado School Management Software', image: m2, price: 'Rs 25,000 / Piece', description: 'Centralized student database with automated attendance tracking and real-time reports.' },
  { title: 'School Vehicle Management', image: m1, price: 'Rs 25,000 / Month', description: 'Manage school vehicles with real-time tracking and maintenance scheduling.' },
  { title: 'Codevocado School ERP Software', image: m3, price: 'Rs 25,000 / Piece', description: 'Comprehensive ERP solution with modules for administration, academics, and more.' },
  { title: 'Codevocado Inventory Management Software', image: m4, price: 'Rs 25,000 / Piece', description: 'Efficient inventory tracking with real-time updates and reporting.' },
  { title: 'Codevocado Visitor Management Software', image: m5, price: 'Rs 25,000 / Piece', description: 'Manage and track visitors with ease and ensure security protocols are followed.' },
  { title: 'Codevocado Library Management Software', image: m6, price: 'Rs 25,000 / Piece', description: 'Streamline library operations with easy book tracking and management.' },
  { title: 'Codevocado Portfolio and Personal Websites', image: m7, price: 'Rs 10,000 / Piece', description: 'Create professional portfolio and personal websites with customized features.' },
  { title: 'Codevocado Online Inventory Software', image: m11, price: 'Rs 2,00,000 / Piece', description: 'Advanced online inventory management with extensive features for large-scale operations.' },
  { title: 'Codevocado AI Chatbots Software', image: m12, price: 'Rs 25,000 / Piece', description: 'Implement AI chatbots to enhance customer interaction and support.' },
  { title: 'Codevocado Helpdesk Software', image: m13, price: 'Rs 2,00,000 / Piece', description: 'Robust helpdesk software with features for ticket management and support.' },
];

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedSoftware, setSelectedSoftware] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const openModal = (software) => {
    setShowModal(true);
    setSelectedSoftware(software);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedSoftware(null);
  };

  const handlePhoneChange = (phone) => {
    setPhoneNumber(phone);
  };

  const handleContactNow = async (event) => {
    event.preventDefault();
    if (!phoneNumber) {
      alert('Please enter a valid phone number.');
      return;
    }

    const payload = {
      service_name: selectedSoftware.title,
      mob: phoneNumber,
    };

    try {
      const response = await axios.post(`${apiBaseUrl}/services/submit-request`, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        alert('Your request has been sent successfully!');
        closeModal();
      } else {
        alert('There was an issue sending your request. Please try again.');
      }
    } catch (error) {
      console.error('Error sending request:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  return (
    <div>
      <header className="header">
        <div className="container">
          <div className="logo">
            <img src={logo} alt="Logo" className="logo-img" />
          </div>
          <nav className="navbar">
            <ul className={`nav-list ${menuOpen ? 'active' : ''}`}>
              <li className="nav-item"><a href="#header">Home</a></li>
              <li className="nav-item"><a href="#aboutus">About Us</a></li>
              <li className="nav-item"><a href="#services">Services</a></li>
              <li className="nav-item"><a href="#inquiry">Contact</a></li>
              <li className="nav-item"><a href="#career">Career</a></li>
            </ul>
            <div className="hamburger" onClick={toggleMenu}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </nav>
        </div>

        <div className="carousel-container">
          <Carousel
            showThumbs={false}
            showStatus={false}
            infiniteLoop
            autoPlay
            interval={3000}
            transitionTime={500}
            showIndicators={false}
          >
            {softwareData.map((software) => (
              <div className="carousel-slide" key={software.title}>
                <img src={software.image} alt={software.title} />
                <div className="carousel-content">
                  <h3>{software.title}</h3>
                  <button className="purchase-button" onClick={() => openModal(software)}>
                    Purchase this software
                  </button>
                  <p className="price">{software.price}</p>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </header>

      {showModal && selectedSoftware && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={closeModal}>&times;</span>
            <div className="modal-body">
              <div className="modal-left">
                <img src={selectedSoftware.image} alt={selectedSoftware.title} className="modal-image" />
                <h2>{selectedSoftware.title}</h2>
                <p>{selectedSoftware.description}</p>
                <p>Price: {selectedSoftware.price}</p>
              </div>
              <div className="modal-right">
                <form onSubmit={handleContactNow}>
                  <label htmlFor="mobile-number">Mobile Number:</label>
                  <PhoneInput
                    country={'in'}
                    value={phoneNumber}
                    onChange={handlePhoneChange}
                    inputProps={{
                      name: 'mobile-number',
                      required: true,
                      autoFocus: true
                    }}
                    containerStyle={{
                      width: '100%', // Ensure container is full width
                      marginBottom: '20px' // Space between input and button
                    }}
                    inputStyle={{
                      width: '100%', // Full width of the container
                      height: '40px', // Increase height for better visibility
                      fontSize: '18px', // Increase font size for readability
                      borderRadius: '8px', // Rounded corners
                      padding: '0 15px', // Increased padding for better visibility
                      boxSizing: 'border-box' // Include padding in width
                    }}
                  />
                  <button type="submit" className="submit-button">Contact Now</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
