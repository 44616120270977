import React from 'react';
import './ValueAddition.css';

function ValueAddition() {
  const values = [
    { icon: '💵', text: 'Minimum cost for high ROI' },
    { icon: '⭐', text: 'Excellent quality standards' },
    { icon: '⚙️', text: 'Agile & lean development approach' },
    { icon: '❤️', text: 'Design that user will love' },
    { icon: '📊', text: 'Measurable work insight' },
    { icon: '🔍', text: 'Test driven development' },
    { icon: '⬆️', text: 'Focus on high value activities' },
    { icon: '📞', text: 'Active support even post launch' }
  ];

  return (
    <div className="Value-addition-page">
      <section className="Value-addition">
        <h2>What Will Drive Value Addition?</h2>
        <div className="Value-cards">
          {values.map((value, index) => (
            <div key={index} className="Value-card">
              <span className="icon">{value.icon}</span>
              <p>{value.text}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default ValueAddition;
