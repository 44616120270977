import React from 'react';
import { FaArrowUp } from 'react-icons/fa'; // Import the scroll icon from react-icons
import './Scroll.css'; // Ensure you have a corresponding CSS file

const ScrollButton = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <button className="scroll-button" onClick={scrollToTop}>
      <FaArrowUp size={24} /> {/* Adjust size as needed */}
    </button>
  );
};

export default ScrollButton;
