import React, { useState } from 'react';
import { TextField, Button, Typography, Box, Grid, Paper } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import axios from 'axios'; // Import axios for making API requests

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

function App() {
  const [enquiry, setEnquiry] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({
    enquiry: false,
    phone: false,
    name: false,
    email: false
  });
  const [submitStatus, setSubmitStatus] = useState(''); // For displaying success or error messages

  const handleSubmit = async (event) => {
    event.preventDefault();

    let valid = true;
    const newErrors = {
      enquiry: !enquiry,
      phone: phone.length !== 10,
      name: !name,
      email: !email
    };

    if (Object.values(newErrors).some(error => error)) {
      setErrors(newErrors);
      valid = false;
    } else {
      setErrors({ enquiry: false, phone: false, name: false, email: false });
    }

    if (valid) {
      try {
        // Make the API call
        const response = await axios.post(`${apiBaseUrl}/contact/submit`, {
          inquiry: enquiry,
          phone_no: phone,
          name: name,
          email: email
        });

        // Handle success
        setSubmitStatus('Enquiry submitted successfully!');
        console.log("Response:", response.data);
      } catch (error) {
        // Handle error
        setSubmitStatus('An error occurred while sending the enquiry.');
        console.error("Error:", error);
      }
    }
  };

  return (
    <Box
      sx={{
        bgcolor: '#f5f7fa',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
      }}
    >
      <Paper
        elevation={3}
        style={{
          width: '100%',
          maxWidth: '600px',
          padding: '30px',
          borderRadius: '12px',
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          backgroundColor: '#fff',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          style={{
            fontWeight: 'bold',
            color: '#3f51b5',
            marginBottom: '20px'
          }}
        >
          Tell Us What You Need
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px'
          }}
        >
          <TextField
            label="Your Enquiry"
            fullWidth
            multiline
            rows={3}
            variant="outlined"
            value={enquiry}
            onChange={(e) => setEnquiry(e.target.value)}
            error={errors.enquiry}
            helperText={errors.enquiry && "This field is required"}
            style={{
              backgroundColor: '#f9f9f9',
              borderRadius: '8px',
              border: '1px solid #d1d1d1'
            }}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <PhoneInput
                country={'in'}
                value={phone}
                onChange={setPhone}
                inputStyle={{
                  width: '100%',
                  height: '56px',
                  background: '#f9f9f9',
                  borderRadius: '8px',
                  border: '1px solid #d1d1d1',
                  // padding: '10px'
                }}
                containerStyle={{ width: '100%' }}
                specialLabel="" // Hide the default label
                isValid={(value) => value.replace(/\D/g, '').length === 10} // Custom validation for exactly 10 digits
                defaultCountry="IN"
                onlyCountries={['in']}
              />
              {errors.phone && (
                <Typography
                  color="error"
                  variant="body2"
                  style={{ marginTop: '8px' }}
                >
                  Phone number must be exactly 10 digits
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Enter your name"
                fullWidth
                type="name"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={errors.name}
                helperText={errors.name && "This field is required"}
                style={{
                  backgroundColor: '#f9f9f9',
                  borderRadius: '8px',
                  border: '1px solid #d1d1d1'
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Your Email ID"
                fullWidth
                type="email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={errors.email}
                helperText={errors.email && "This field is required"}
                style={{
                  backgroundColor: '#f9f9f9',
                  borderRadius: '8px',
                  border: '1px solid #d1d1d1'
                }}
              />
            </Grid>
          </Grid>
          <Box
            textAlign="center"
            style={{
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{
                padding: '12px 24px',
                fontSize: '16px',
                borderRadius: '6px',
                width: '100%',
                maxWidth: '200px',
                backgroundColor: '#3f51b5',
                color: '#fff',
                transition: 'background-color 0.3s ease'
              }}
              type="submit"
            >
              Send Enquiry
            </Button>
          </Box>
          {submitStatus && (
            <Typography
              variant="body1"
              align="center"
              style={{
                marginTop: '20px',
                color: submitStatus.includes('Error') ? 'red' : 'green',
                fontSize: '16px'
              }}
            >
              {submitStatus}
            </Typography>
          )}
        </Box>
      </Paper>
    </Box>
  );
}

export default App;
