import React from 'react';
import './Offer.css';
import webDevImage from '../assets/voice.jpg'; // Adjust the path accordingly
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faChartLine, faComments, faHeadset, faProjectDiagram } from '@fortawesome/free-solid-svg-icons';

function Offer() {
  return (
    <section id="Offer">
      <div className="Offer-container">
        <div className="Offer-content">
          <h2>Why Choose Voice Developer From Codevocado</h2>
          <div className="Offer-item">
            <FontAwesomeIcon icon={faUser} className="icon" />
            <h5>1. Dedicated Resource on demand</h5>
          </div>
          <div className="Offer-item">
            <FontAwesomeIcon icon={faChartLine} className="icon" />
            <h5>2. Daily/weekly/monthly reporting</h5>
          </div>
          <div className="Offer-item">
            <FontAwesomeIcon icon={faComments} className="icon" />
            <h5>3. Direct communication with developers</h5>
          </div>
          <div className="Offer-item">
            <FontAwesomeIcon icon={faHeadset} className="icon" />
            <h5>4. Uninterrupted support with no extra cost</h5>
          </div>
          <div className="Offer-item">
            <FontAwesomeIcon icon={faProjectDiagram} className="icon" />
            <h5>5. Complete control over your project and hired team</h5>
          </div>
        </div>
        <div className="Offer-image">
          <div className="circle-container">
            <img src={webDevImage} alt="Web Development" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Offer;
