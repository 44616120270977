import React, { useState } from 'react';
import {
  Box, Typography, Button, Dialog, DialogActions, DialogContent, 
  DialogContentText, DialogTitle, TextField, MenuItem, Select, 
  FormControl, InputLabel 
} from '@mui/material';
import axios from 'axios';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const FreeTrialSection = () => {
  const [open, setOpen] = useState(true); // Automatically open the modal on page load
  const [selectedSoftware, setSelectedSoftware] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [otp, setOtp] = useState(''); // State for OTP
  const [otpSent, setOtpSent] = useState(false); // State to track if OTP is sent
  const [otpVerified, setOtpVerified] = useState(false); // State to track if OTP is verified
  const [error, setError] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendOtp = async () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    try {
      const response = await axios.post(`${apiBaseUrl}/free-trial/send-otp`, {
        email, // Use email instead of mobileNumber
      });

      if (response.data.message === 'OtpSent') {
        alert('OTP has been sent to your email address. If you do not receive it, please check your spam folder.');
        setOtpSent(true);
        setError('');
      } else {
        setError('Failed to send OTP. Please try again.');
      }
    } catch (error) {
      setError('An error occurred while sending OTP. Please try again.');
    }
  };

  const handleVerifyOtp = async () => {
    if (!otp) {
      setError('Please enter the OTP.');
      return;
    }

    try {
      const response = await axios.post(`${apiBaseUrl}/free-trial/verify-otp`, {
        email, // Use email instead of mobileNumber
        otp,
      });

      if (response.data.message === 'OtpVerified') {
        alert('OTP has been verified successfully.');
        setOtpVerified(true);
        setError('');
      } else {
        setError('Invalid OTP. Please try again.');
      }
    } catch (error) {
      setError('An error occurred while verifying OTP. Please try again.');
    }
  };

  const handleSubmit = async () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!selectedSoftware || !name || !email || !mobileNumber) {
      setError('All fields are required.');
      return;
    } else if (!emailPattern.test(email)) {
      setError('Please enter a valid email address.');
      return;
    } else if (!otpVerified) {
      setError('Please verify your email with OTP.');
      return;
    }

    try {
      const response = await axios.post(`${apiBaseUrl}/free-trial/request-free-trial`, {
        selectedSoftware,
        name,
        email,
        mobileNumber,
      });

      if (response.data.message === 'Success') {
        alert('Your free trial request has been submitted.');
        setOpen(false);

        // Reset form fields
        setSelectedSoftware('');
        setName('');
        setEmail('');
        setMobileNumber('');
        setOtp('');
        setOtpSent(false);
        setOtpVerified(false);
        setError('');
      } else {
        setError('Failed to submit your request. Please try again.');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <Box
      sx={{
        textAlign: 'center',
        padding: '40px 20px',
        backgroundColor: '#f7f7f7',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Try Our Software Free for One Month!
      </Typography>
      <Typography variant="body1" gutterBottom>
        Experience all the features and benefits without any commitment. Sign up now and take the first step towards optimizing your workflow.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size="large"
        sx={{
          marginTop: '20px',
          padding: '10px 20px',
          borderRadius: '5px',
        }}
        onClick={() => setOpen(true)}
      >
        Start Your Free Trial
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-paper': {
            border: '5px solid #3f51b5', // Highlight the modal with a primary color border
            boxShadow: '0px 0px 20px 5px rgba(63, 81, 181, 0.5)', // Add a shadow for emphasis
            padding: '20px',
            animation: 'modalFadeIn 0.5s', // Smooth fade-in animation
          }
        }}
      >
        <DialogTitle>Start Your Free Trial</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select the software and enter your details to begin your free trial.
          </DialogContentText>

          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}

          <FormControl fullWidth margin="dense" required>
            <InputLabel id="software-select-label">Select Software</InputLabel>
            <Select
              labelId="software-select-label"
              id="software-select"
              value={selectedSoftware}
              label="Select Software"
              onChange={(e) => setSelectedSoftware(e.target.value)}
            >
              <MenuItem value="1">Codevocado School Management Software</MenuItem>
              <MenuItem value="2">Codevocado’s School ERP Software</MenuItem>
              <MenuItem value="3">Codevocado’s Inventory Management Software</MenuItem>
              <MenuItem value="4">Codevocado’s Visitor Management Software</MenuItem>
              <MenuItem value="5">Codevocado’s Library Management Software</MenuItem>
            </Select>
          </FormControl>

          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Full Name"
            type="name"
            fullWidth
            variant="outlined" 
             required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <TextField
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined" 
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <TextField
            margin="dense"
            id="mobileNumber"
            label="Mobile Number"
            type="mobile"
            fullWidth
            variant="outlined" 
            required
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
          />
          
          {otpSent && (
            <TextField
              margin="dense"
              id="otp"
              label="Enter OTP"
              type="text"
              fullWidth
              variant="outlined" 
              required
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          )}

          {!otpSent && (
            <Button onClick={handleSendOtp} color="primary" variant="outlined" sx={{ marginTop: '10px' }}>
              Send OTP
            </Button>
          )}

          {otpSent && !otpVerified && (
            <Button onClick={handleVerifyOtp} color="primary" variant="outlined" sx={{ marginTop: '10px' }}>
              Verify OTP
            </Button>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="secondary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary" disabled={!otpVerified}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FreeTrialSection;
