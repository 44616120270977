import React, { useState } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './Chatbot.css';

const Chatbot = () => {
  const [isChatbotOpen, setChatbotOpen] = useState(false);
  const [messages, setMessages] = useState([
    { sender: 'bot', text: 'Hello! How can I help you today?' }
  ]);
  const [userMessage, setUserMessage] = useState('');

  const toggleChatbot = () => {
    setChatbotOpen(!isChatbotOpen);
  };

  const handleInputChange = (e) => {
    setUserMessage(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission

    if (userMessage.trim() !== '') {
      // Add the user's message to the chat
      setMessages([...messages, { sender: 'user', text: userMessage }]);

      // Handle the bot's response
      const botResponse = getBotResponse(userMessage);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'user', text: userMessage },
        { sender: 'bot', text: botResponse }
      ]);

      setUserMessage(''); // Clear the input field
    }
  };

  const getBotResponse = (message) => {
    const lowercasedMessage = message.toLowerCase();

    // Simple logic for answering specific questions
    if (lowercasedMessage.includes('services')) {
      return 'We offer a variety of services including web development, app development, and more!';
    } else if (lowercasedMessage.includes('pricing')) {
      return 'Our pricing is competitive and depends on the scope of the project. Please contact us for more details.';

    }
    else if (lowercasedMessage.includes('hii')) {
      return 'hii nyc to meet you i am codevocado helpdesk just ask me your query.';

    }
    else if (lowercasedMessage.includes('tell me about this company')) {
      return 'this company is a development and sell the all kind of software for a releiable prize of satisfraction of the user.';

    }
     else if (lowercasedMessage.includes('contact')) {
      return 'You can contact us via email at info@yourwebsite.com or call us at 123-456-7890.';
    } else if (lowercasedMessage.includes('location')) {
      return 'Our office is located at 1234 Street Name, City, Country.';
    } else {
      return 'I\'m sorry, I didn\'t understand that. Can you please rephrase your question?';
    }
  };

  return (
    <div>
      {/* Chatbot Button */}
      <button className="chatbot-button" onClick={toggleChatbot}>
        <i className="fas fa-robot" style={{ fontSize: '60px', color: '#007bff' }}></i> {/* Chatbot icon */}
      </button>

      {/* Chatbot Container */}
      {isChatbotOpen && (
        <div className="chatbot-container">
          <button className="close-button" onClick={toggleChatbot}>×</button>
          <div className="chatbot-messages">
            {messages.map((message, index) => (
              <div key={index} className={`message ${message.sender}`}>
                {message.text}
              </div>
            ))}
          </div>
          <form onSubmit={handleFormSubmit}>
            <input
              type="text"
              placeholder="Type your message..."
              value={userMessage}
              onChange={handleInputChange}
            />
            <button type="submit">Send</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Chatbot;
