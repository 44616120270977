import React, { useState, useEffect } from 'react';
import { FaLaptopCode, FaBullhorn, FaUsers, FaChartLine, FaCertificate, FaHandshake, FaClipboardList, FaArrowRight } from 'react-icons/fa';
import certificateImage from '../assets/certificate.png'; // Import your certificate image

const styles = {
  careerPage: {
    padding: '40px',
    fontFamily: 'Arial, sans-serif',
    textAlign: 'center',
    backgroundColor: '#f4f4f4',
  },
  title: {
    fontSize: '2.5em',
    marginBottom: '20px',
    color: '#2c3e50',
    lineHeight: '1.2',
  },
  description: {
    fontSize: '1.2em',
    color: '#7f8c8d',
    marginBottom: '30px',
  },
  positions: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    marginTop: '30px',
  },
  position: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)',
    width: '300px',
    marginBottom: '20px',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transition: 'transform 0.3s, box-shadow 0.3s',
  },
  positionHover: {
    transform: 'scale(1.05)',
    boxShadow: '0 8px 24px rgba(0, 0, 0, 0.2)',
  },
  icon: {
    fontSize: '2.5em',
    color: '#2980b9',
    marginBottom: '15px',
  },
  positionTitle: {
    fontSize: '1.5em',
    marginBottom: '10px',
    color: '#2c3e50',
  },
  positionDescription: {
    fontSize: '1em',
    color: '#7f8c8d',
    flexGrow: 1,
  },
  applyButton: {
    backgroundColor: '#2980b9',
    color: 'white',
    padding: '12px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '1.1em',
    transition: 'background-color 0.3s, transform 0.3s',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  applyButtonHover: {
    backgroundColor: '#3498db',
    transform: 'scale(1.05)',
  },
  benefits: {
    marginTop: '50px',
  },
  benefitsList: {
    listStyleType: 'none',
    padding: '0',
    fontSize: '1.1em',
    color: '#7f8c8d',
  },
  benefitItem: {
    margin: '10px 0',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.2em',
  },
  benefitIcon: {
    marginRight: '10px',
    color: '#27ae60',
  },
  certificateImage: {
    width: '750px',
    height: '450px',
    marginTop: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
  applicationProcess: {
    marginTop: '50px',
    backgroundColor: '#ecf0f1',
    padding: '20px',
    borderRadius: '10px',
  },
  applicationProcessTitle: {
    fontSize: '1.5em',
    marginBottom: '10px',
    color: '#2c3e50',
  },
  applicationProcessDescription: {
    fontSize: '1.2em',
    color: '#7f8c8d',
  },
  modal: {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '1000',
    transition: 'opacity 0.3s ease',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    margin: 'auto',
    padding: '30px',
    border: '1px solid #888',
    width: '90%',
    maxWidth: '700px',
    maxHeight: '90%',
    height: 'auto',
    overflowY: 'auto',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
    animation: 'fadeIn 0.3s ease',
  },
  closeButton: {
    position: 'absolute',
    top: '15px',
    right: '15px',
    backgroundColor: '#e74c3c',
    border: 'none',
    color: 'white',
    fontSize: '1.6em',
    cursor: 'pointer',
    borderRadius: '50%',
    width: '35px',
    height: '35px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color 0.3s',
  },
  closeButtonHover: {
    backgroundColor: '#c0392b',
  },
  formContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  formGroup: {
    marginBottom: '20px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    fontWeight: 'bold',
    color: '#2c3e50',
  },
  input: {
    width: '100%',
    padding: '15px',
    height: '45px',
    borderRadius: '5px',
    border: '1px solid #ddd',
    fontSize: '1em',
    boxSizing: 'border-box',
  },
  fileInput: {
    width: '100%',
    padding: '15px',
    height: '45px',
    borderRadius: '5px',
    border: '1px solid #ddd',
    fontSize: '1em',
    boxSizing: 'border-box',
  },
  submitButton: {
    backgroundColor: '#2980b9',
    color: 'white',
    padding: '15px',
    height: '50px',
    width: '100%',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '1.2em',
    transition: 'background-color 0.3s, transform 0.3s',
    marginTop: '20px',
  },
  submitButtonHover: {
    backgroundColor: '#3498db',
    transform: 'scale(1.05)',
  },
};

const Position = ({ icon: Icon, title, description, onApplyClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      style={{
        ...styles.position,
        ...(isHovered && styles.positionHover),
      }}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      <Icon style={styles.icon} />
      <h2 style={styles.positionTitle}>{title}</h2>
      <p style={styles.positionDescription}>{description}</p>
      <button
        style={{
          ...styles.applyButton,
          ...(isHovered && styles.applyButtonHover),
        }}
        onClick={onApplyClick}
      >
        Apply Now <FaArrowRight style={{ marginLeft: '10px' }} />
      </button>
    </div>
  );
};

function CareerPage() {
  const [showModal, setShowModal] = useState(false);

  const handleApplyClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showModal]);

  return (
    <div style={styles.careerPage}>
      <h1 style={styles.title}>Join Our Team at Codevocado</h1>
      <p style={styles.description}>
        At Codevocado, we are looking for talented individuals to join our dynamic team.
        Explore our open positions below and apply to be part of our innovative journey!
      </p>

      <div style={styles.positions}>
        <Position
          icon={FaLaptopCode}
          title="Software Engineer"
          description="Build and optimize web applications, collaborate with cross-functional teams, and contribute to a high-performing engineering team."
          onApplyClick={handleApplyClick}
        />
        <Position
          icon={FaBullhorn}
          title="Marketing Specialist"
          description="Drive our marketing efforts, develop engaging campaigns, and analyze market trends to enhance our brand presence."
          onApplyClick={handleApplyClick}
        />
        <Position
          icon={FaUsers}
          title="HR Manager"
          description="Lead our HR initiatives, manage talent acquisition, employee relations, and foster a positive work culture."
          onApplyClick={handleApplyClick}
        />
        <Position
          icon={FaChartLine}
          title="Data Analyst"
          description="Analyze data to uncover insights, support decision-making processes, and drive business growth with data-driven strategies."
          onApplyClick={handleApplyClick}
        />
      </div>

      <div style={styles.benefits}>
        <h2 style={styles.title}>Why Join Us?</h2>
        <ul style={styles.benefitsList}>
          <li style={styles.benefitItem}><FaCertificate style={styles.benefitIcon} />Competitive salary packages</li>
          <li style={styles.benefitItem}><FaHandshake style={styles.benefitIcon} />Collaborative and inclusive work environment</li>
          <li style={styles.benefitItem}><FaClipboardList style={styles.benefitIcon} />Comprehensive health and wellness benefits</li>
          <li style={styles.benefitItem}><FaChartLine style={styles.benefitIcon} />Professional development opportunities</li>
          <li style={styles.benefitItem}><FaChartLine style={styles.benefitIcon} />Letter of Recommendation</li>
          <li style={styles.benefitItem}><FaChartLine style={styles.benefitIcon} />Earn a Certificate</li>
        </ul>
      </div>

      <div style={styles.applicationProcess}>
        <h2 style={styles.applicationProcessTitle}>Our Application Process</h2>
        <p style={styles.applicationProcessDescription}>
          Once you submit your application, our hiring team will review your profile. If you are shortlisted, we will reach out to schedule an interview.
        </p>
      </div>

      <img src={certificateImage} alt="Certificate" style={styles.certificateImage} />

      {showModal && (
        <div style={styles.modal}>
          <div style={styles.modalContent}>
            <button
              style={{
                ...styles.closeButton,
                ...(showModal && styles.closeButtonHover),
              }}
              onClick={handleCloseModal}
            >
              &times;
            </button>
            <h2 style={styles.title}>Apply for a Position</h2>
            <form style={styles.formContent}>
              <div style={styles.formGroup}>
                <label style={styles.label} htmlFor="fullName">Full Name</label>
                <input style={styles.input} type="text" id="fullName" name="fullName" />
              </div>
              <div style={styles.formGroup}>
                <label style={styles.label} htmlFor="email">Email Address</label>
                <input style={styles.input} type="email" id="email" name="email" />
              </div>
              <div style={styles.formGroup}>
                <label style={styles.label} htmlFor="resume">Upload Resume</label>
                <input style={styles.fileInput} type="file" id="resume" name="resume" />
              </div>
              <button
                style={{
                  ...styles.submitButton,
                  ...(showModal && styles.submitButtonHover),
                }}
                type="submit"
              >
                Submit Application
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default CareerPage;
